<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        About Us
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container >
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <v-slide-y-transition mode="out-in">
                        <div >
                            <p class="pl-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis vel molestiae illum et, quis quod, labore modi consectetur perspiciatis tempore cupiditate. Ullam totam, sapiente iure nemo eligendi veniam consequuntur ab?</p>
                            <v-layout row wrap>
                                <v-flex xs12 sm6 md4 lg3 text-xs-center v-for="item in about_info_one" :key="item.id" class="pa-1">
                                    <v-hover>
                                        <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 12 : 2}`" style="cursor: pointer; border-radius: 10px">
                                            <v-card-title primary-title class="pa-3 ma-1">
                                                <h3 class="subheadline mb-0 dark--text">{{item.info}} {{item.title}} </h3>
                                            </v-card-title>
                                        </v-card>
                                    </v-hover>

                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout  row wrap class="elevation-8 white pa-4" style="border-radius: 10px">
                                <v-flex xs12 md7 text-xs-left class=" px-2">
                                    <h2 class=" blue--text text-xs-left pt-2"><b>Our Vision</b></h2>
                                    <h3 class="title text-xs-left py-2">BRINGING FINANCIAL KEYS TO EVERYONE</h3>
                                    <p class="pl-2">Petty Cash is a financial educational platform which helps you discipline yourself financially through objective savings. We act as an Intermediary between the individuals and Financial institutions to offer this unique service which permits to be effective in managing their small amounts of money.</p>
                                </v-flex>
                                <v-flex xs12 md5 text-xs-center class="pa-1 mt-2 " outline>
                                    <v-img
                                            :src="require('@/assets/our-vision.png')"
                                            aspect-ratio="1"
                                            height="190px"
                                            contain
                                            width="270px"
                                    > </v-img>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout  row wrap class="elevation-8 white" style="border-radius: 10px">
                                <v-flex xs12>
                                    <v-img
                                        :src="require('@/assets/client_king.jpg')"
                                        aspect-ratio="2"  
                                        style=" border-radius: 10px"
                                    ></v-img>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout  row wrap class="elevation-8 white pa-4" style="border-radius: 10px">
                                <v-flex xs12 md5 text-xs-center class="pa-1 mt-2 " outline>
                                    <v-img
                                            :src="require('@/assets/our-mission.png')"
                                            aspect-ratio="1"
                                            height="190px"
                                            contain
                                            width="270px"
                                    > </v-img>
                                </v-flex>
                                <v-flex xs12 md7 text-xs-right class=" px-2">
                                    <h2 class=" blue--text text-xs-right pt-2 "><b>Our Mission</b></h2>
                                    <h3 class="title text-xs-right py-2 ">BRINGING FINANCIAL KEYS TO EVERYONE</h3>
                                    <p class="pl-2"> We act as an Intermediary between the individuals and Financial institutions to offer this unique service which permits people of all walks of life to belong to a structured financial and sociocultural network especially individuals without bank accounts and who desire to be effective in managing their small amounts of money.</p>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                about_info_one: [],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            this.getAboutUsInfo();
            this.$store.commit('setCloseURL', "/")
        },
        methods:{
            async getAboutUsInfo(){
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/home/get/about_us/info/')
                    .then(response => {
                        this.about_info_one = response.data
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            }
        }

    }
</script>
